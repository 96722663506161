import { MatTableDataSource } from '@angular/material/table';
import { AfterViewInit, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { Device } from '../../../shared/models/device';
import { DeviceBrand } from '../../../shared/models/deviceBrand';
import { DeviceDeleteRequest } from '../../../shared/models/deviceDeleteRequest';
import { DeviceModel } from '../../../shared/models/deviceModel';
import { DevicePropertyRequest } from '../../../shared/models/devicePropertyRequest';
import { DeviceUpdateRequest } from '../../../shared/models/deviceUpdateRequest';
import { Dictionary } from '../../../shared/models/dictionary';
import { UserRoles } from '../../../shared/models/userRoles';
import { ApiService } from '../../../shared/services/api.service';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AddDeviceDialogComponent } from '../../../shared/dialogs/add-device-dialog/add-device-dialog.component';
import { Router } from '@angular/router';
import { StreamingDialogComponent } from '../../../shared/dialogs/streaming-dialog/streaming-dialog.component';
import { ClientSettings } from '../../../shared/models/clientSettings';
import { TranslateService } from '@ngx-translate/core';
import { Translation } from '../../../shared/models/translation';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { first, takeUntil } from 'rxjs/operators';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { StreamingLinkDialogComponent } from '../../../shared/dialogs/streaming-link-dialog/streaming-link-dialog.component';
import { DeviceAlias } from '../../../shared/models/deviceAlias';
import { AddDeviceAliasDialogComponent } from '../../../shared/dialogs/add-device-alias-dialog/add-device-alias-dialog.component';
import { DeviceGroupSetDialogComponent } from '../../../shared/dialogs/device-group-set-dialog/device-group-set-dialog.component';
import { AdministratorApiService } from '../../../shared/services/administrator-api.service';
import { DeviceGroup } from '../../../shared/models/deviceGroup';
import { DeviceGroupCapability } from '../../../shared/models/deviceGroupCapability';
import { SerializationHelper } from '../../../serializationHelper';
import { SmartDialogComponent } from '../../../shared/dialogs/smart-dialog/smart-dialog.component';
import { SetDomainDialogComponent } from 'src/app/shared/dialogs/set-domain-dialog/set-domain-dialog.component';
import { Domain } from 'src/app/shared/models/domain';
import { ManualEventDialogComponent } from "../../../shared/dialogs/manual-event-dialog/manual-event-dialog.component";
import { Clipboard } from '@angular/cdk/clipboard';
import { PaginationInstance } from 'ngx-pagination';
import { DeviceTypeCapability } from 'src/app/shared/models/deviceTypeCapability';

@Component({
  selector: 'urban-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit, AfterViewInit, OnDestroy {

  private updateRequest: DeviceUpdateRequest;
  private deleteRequest: DeviceDeleteRequest;
  private devicePropertyModel: DevicePropertyRequest;
  private listDevices: Device[] = [];
  private listBrands: DeviceBrand[];
  private listModels: DeviceModel[];
  private deviceType: string = '';
  private clientSettings: ClientSettings;
  private translations: Translation[];
  public isHexadecimal: boolean = false;
  public parents: Device[];
  public currentDevice: Device = null;
  public currentDeviceLocalized: Device = null;
  public lat: number;
  public lng: number;
  public properties: Array<Dictionary>
  public childs: Device[];
  public childsDataSource: MatTableDataSource<Device>;
  public filteredChildsData: Device[] = [];
  public childsColumns: string[] = ['Icon', 'Name', 'Action'];
  public isAdmin: boolean;
  public userRoles: UserRoles['Roles'];
  public hasRetention: boolean = false;
  public currentLanguage: string;
  public brands$: Observable<DeviceBrand[]>;
  public models$: Observable<DeviceModel[]>;
  public showFormError: boolean = false;
  public showLocationFormError: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public streamLink: string = '';
  public deviceAliases: DeviceAlias[] = [];
  public aliasesDataSource: MatTableDataSource<DeviceAlias>;
  public filteredAliasesData: DeviceAlias[] = [];
  public aliasesColumns: string[] = ['Alias', 'Action'];
  public deviceGroups: DeviceGroup[] = [];
  public groupsDataSource: MatTableDataSource<DeviceGroup>;
  public filteredGroupsData: DeviceGroup[] = [];
  public groupsColumns: string[] = ['Name', 'Action'];
  public isDomainAdmin: boolean = false;
  public aliasesActualFilter: string = '';
  public childsActualFilter: string = '';
  public groupsActualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public aliasesConfig: PaginationInstance = {
    id: 'aliasesData',
    itemsPerPage: 10,
    currentPage: 1,
  }
  public childsConfig: PaginationInstance = {
    id: 'childsData',
    itemsPerPage: 10,
    currentPage: 1,
  }
  public groupsConfig: PaginationInstance = {
    id: 'groupsData',
    itemsPerPage: 10,
    currentPage: 1,
  }

  @ViewChild('decHex') idDecHex: ElementRef;

  public editMode: 'info' | 'location' | null = null;
  private dataEditBackup: Record<string, string> = null;
  private dataChanged: boolean = false;

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '' }, Validators.required],
    brand: [{ value: '' }, Validators.required],
    id: [{ value: '' }, Validators.required],
    model: ['', Validators.required],
    parentSelected: [''],
    yearCost: [0],
    monthCost: [0]
  });

  public locationForm: UntypedFormGroup = this.formBuilder.group({
    latitude: ['', Validators.required],
    longitude: ['', Validators.required]
  })
  public mapReady: boolean = false;
  public isDarkModeActive: boolean = false;

  public formFields: Record<string, string>[];

  public actionCapabilitiesLabelsList: string[] = ['SEND_COMMAND'];
  public cameraCapabilities: string[] = ['LIVE_STREAMING', 'HISTORY', 'PREVIEW'];


  constructor(
    private mainService: MainSubscriptionsService,
    public dialog: MatDialog,
    private apiService: ApiService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private passDataService: PassDataService,
    private sanitizer: DomSanitizer,
    private administratorApiService: AdministratorApiService,
    private clipboard: Clipboard
  ) {
  }

  ngOnInit(): void {
    let deviceId: string;

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      this.isAdmin = this.userRoles.some(x => x.Name == 'Administrators');
      this.isDomainAdmin = this.userRoles.some(x => x.Name == 'Domain admin');
    });

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;
      } else {
          this.mainService.setNavigationInfoComand();
          this.router.navigate(['main/dashboard']);
          return;
      }

      if(navInfo?.Type) {
        this.deviceType = navInfo.Type;
      }

      this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
        this.mapReady = mapLoading;
      });

      this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.isDarkModeActive = res == true;
      });

      this.initializeDevice(deviceId);
    });

    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: any) => {
      this.showFormError = false;

      if(this.dataEditBackup && !this.dataChanged) {
        this.dataChanged = true;
      }
    });

    this.locationForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: any) => {
      this.showLocationFormError = false;

      if(this.dataEditBackup && !this.dataChanged) {
        this.dataChanged = true;
      }
    });
  }

  ngAfterViewInit(): void {
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(newLang => {
      this.currentLanguage = newLang.lang.slice(-2);
      this.apiService.getTranslations(this.currentLanguage).pipe(takeUntil(this.ngUnsubscribe)).subscribe(translations => {
        this.translations = translations;
        this.properties?.forEach(property => {
          const translation = this.translations.find(translate => translate.Original == property.Key);
          property.Translation = translation !== undefined && translation !== null && translation.Translated !== '' ? translation.Translated : property.Key;
        });
      });
    })
  }

  private initializeFormFields(): void {
    this.formFields = [
      { formControl: 'id' },
      { formControl: 'brand', formLabel: 'DEVICE.BRAND', readonly: 'true', linked: 'true' },
      { formControl: 'model', formLabel: 'DEVICE.MODEL', readonly: 'true', linked: 'true' },
      { formControl: 'name', formLabel: 'DEVICE.NAME', required: 'true' },
      { formControl: 'parentSelected', formLabel: 'DEVICE.PARENT', linked: 'true' }
    ];
    if (this.isAdmin) {
      this.formFields.push({ formButton: 'changeDomain' });
    }
  }

  private initializeDevice(deviceId: string): void {
    this.currentDeviceLocalized = null;

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.currentDevice = device;

        this.currentDevice.Model.Type.Capabilities = this.currentDevice.Model.Type.Capabilities?.filter(x => x.IsVisible);
        let sortedCapabilities: DeviceTypeCapability[] = this.currentDevice.Model.Type.Capabilities.filter(x => !this.actionCapabilitiesLabelsList.includes(x.Label));
        sortedCapabilities.push(...this.currentDevice.Model.Type.Capabilities.filter(x => this.actionCapabilitiesLabelsList.includes(x.Label)));
        this.currentDevice.Model.Type.Capabilities = sortedCapabilities;

        this.passDataService.devices$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(devices => {
          this.listDevices = [];
          this.loadDevices(devices, device => device.Id != this.currentDevice.Id);
          this.listDevices.sort((a, b) => a.Name.localeCompare(b.Name))
        });
        this.passDataService.deviceBrands$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(brands => {
          this.listBrands = brands;
        });
        this.passDataService.deviceModels$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(models => {
          this.listModels = models;
        });
        if (this.currentDevice === undefined || this.currentDevice === null) {
          this.mainService.setNavigationInfoComand();
          this.router.navigate(['main/dashboard']);
        }

        this._loadAliases();

        if (this.currentDevice.Childs?.length > 0) {
          this.childs = [...this.currentDevice.Childs].sort((a, b) => a.Name.localeCompare(b.Name));
          this.childsDataSource = new MatTableDataSource(this.childs);
          this.filteredChildsData = this.childsDataSource.data;
        } else {
          this.childsDataSource = new MatTableDataSource();
        }
        this.clientSettings = new ClientSettings();

        this.removeFormPropertiesFields();
        this.properties = new Array<Dictionary>();

        this.initializeFormFields();
        this.loadProperties();
        // questi parents sono tutti i devices esclusi il device attuale stesso e i suoi figli
        this.parents = this.listDevices.filter(device => device.Id !== this.currentDevice.Id && !this.childs?.some(child => child.Id === device.Id));
        let parentId: string = this.currentDevice.ParentId != '00000000-0000-0000-0000-000000000000' && this.currentDevice.ParentId != null ? this.currentDevice.ParentId : '';
        if (parentId !== '') {
          this.currentDevice.Parent = this.parents.find(parent => parent.Id === parentId);
        }
        //let parent: Device = parentId !== '' ? this.parents.find(x => x.Id === this.currentDevice.ParentId) : null;

        this.form.patchValue({
          name: this.currentDevice.Name,
          id: this.currentDevice.Id,
          brand: this.currentDevice.Model.Brand.Name,
          model: this.currentDevice.Model.Name,
          parentSelected: parentId,
          yearCost: 0,
          monthCost: 0
        });

        this.form.disable();

        this.currentDeviceLocalized = {
          ...this.currentDevice,
          Latitude: this.navigateFamilyForLatLng(this.currentDevice).lat,
          Longitude: this.navigateFamilyForLatLng(this.currentDevice).lng,
        }

        this.locationForm.setValue({
          latitude: this.currentDeviceLocalized.Latitude,
          longitude: this.currentDeviceLocalized.Longitude
        });

        this.locationForm.disable();

        if (this.isAdmin || this.isDomainAdmin) {
          this.administratorApiService.deviceGroupList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(groups => {
            if (groups) {
              this.currentDevice?.Groups?.forEach(element => {
                this.deviceGroups = groups.filter(group => group.Id === element.Id);
              });

              this.groupsDataSource = new MatTableDataSource<DeviceGroup>(this.deviceGroups);
              this.filteredGroupsData = this.groupsDataSource.data;
            }
          })
        }
      }
      else {
        this.mainService.setCustomErrorComand('Could not find a device with passed ID');
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }
    })
  }

  private loadDevices(devicesToIterate: Device[], deviceRule: (a: Device) => boolean = () => true): void {
    // takes all levels devices (that play by the deviceRule) and puts them into the array
    if (devicesToIterate && devicesToIterate.length > 0) {
      for (let device of devicesToIterate) {
        if (deviceRule(device)) {
          this.listDevices.push(device);
        }
        if (device.Childs?.length > 0) {
          this.loadDevices(device.Childs, deviceRule);
        }
      }
    }
  }

  private loadProperties(): void {
    this.currentLanguage = this.translate.currentLang.slice(-2);

    this.apiService.getTranslations(this.currentLanguage).pipe(takeUntil(this.ngUnsubscribe)).subscribe(translations => {
      this.translations = translations;

      this.currentDevice.Model.Properties.forEach(key => {
        let value: string;
        if (key === 'Retention') {
          this.hasRetention = true;
        }
        else if (key === 'Url') {
          this.streamLink = this.currentDevice.Properties[key];
        }
        value = this.currentDevice.Properties[key];
        if (!value) value = ''
        let translation: string = this.translations.find(x => x.Original === key)?.Translated;
        this.properties.push(new Dictionary(key, value, translation));
        this.form.addControl('property' + key, new FormControl({ value: value, disabled: true }));
        this.formFields.push({ propertyKey: key, translation: translation ? translation : key });
      });

      if (this.hasRetention) {
        this.formFields.push(
          { formControl: 'yearCost', formLabel: 'DEVICE.YEAR_COST', readonly: 'true' },
          { formControl: 'monthCost', formLabel: 'DEVICE.MONTH_COST', readonly: 'true' }
        );
      }

      const retention = this.properties.find(property => property.Key === 'Retention') !== undefined ? this.properties.find(property => property.Key === 'Retention').Value : ''

      this.apiService.getClientSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(clientSettings => {
        if (clientSettings !== undefined) {
          this.clientSettings.Settings = clientSettings;
          if (this.properties !== undefined && retention !== '') {
            this._setCosts(retention);
          }
        }
      })
    });
  }

  private removeFormPropertiesFields(): void {
    this.properties?.forEach((property: Dictionary) => {
      this.form.removeControl('property' + property.Key);
    });
  }

  _loadAliases(): void {
    this.apiService.getDeviceAliases(this.currentDevice.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(aliases => {
      if (aliases) {
        this.deviceAliases = aliases;
        this.aliasesDataSource = new MatTableDataSource<DeviceAlias>(this.deviceAliases);
        this.filteredAliasesData = this.aliasesDataSource.data;
      }
    });
  }

  public updateParent(): void {
    if (this.form.controls.parentSelected.value != '' && this.isAdmin) {
      const parentDevice = this.parents.find(x => x.Id == this.form.controls.parentSelected.value);
      if (parentDevice !== null && parentDevice !== undefined && parentDevice.ParentId != '00000000-0000-0000-0000-000000000000') {
        let latitude = this.navigateFamilyForLatLng(parentDevice).lat;
        let longitude = this.navigateFamilyForLatLng(parentDevice).lng;
        this.locationForm.controls.latitude.setValue(latitude);
        this.locationForm.controls.longitude.setValue(longitude);
      }
    }
  }

  public navigateFamilyForLatLng(startingDevice: Device): { lat: number, lng: number } {
    if (!(startingDevice.Latitude == 0 && startingDevice.Longitude == 0) && startingDevice.Latitude !== null && startingDevice.Longitude !== null) {
      return { lat: startingDevice.Latitude, lng: startingDevice.Longitude };
    } else if (startingDevice.Latitude === 0 && startingDevice.Longitude === 0 && startingDevice.ParentId != '00000000-0000-0000-0000-000000000000' && startingDevice.ParentId !== null) {
      return this.navigateFamilyForLatLng(this.listDevices.find(x => x.Id == startingDevice.ParentId));
    }
  }

  public childDetail(child: Device): void {
    if (child) {
      this.mainService.setNavigationInfoComand({ Id: child.Id, BackRoute: 'device' })
      this.router.navigate(['main/invisible-child'], { skipLocationChange: true }).then(() => {
        this.router.navigate(['main/device']);
      });
    }
  }

  public parentDetail(): void {
    if (this.currentDevice.ParentId) {
      let parent = this.parents.find(x => x.Id == this.currentDevice.ParentId);

      if (parent && parent !== undefined) {
        this.mainService.setNavigationInfoComand({ Id: parent.Id, BackRoute: 'device' });
        this.router.navigate(['main/invisible-child'], { skipLocationChange: true }).then(() => {
          this.router.navigate(['main/device']);
        })
      }
    }
  }

  public detail(type: string): void {
    switch (type) {
      case 'brand':
        this.brandDetail();
        break;
      case 'model':
        this.modelDetail();
        break;
      default:
        break;
    }
  }

  private brandDetail(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Model.Brand.Id, BackRoute: 'device' });
    this.router.navigate(['main/device-brand-detail']);
  }

  private modelDetail(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Model.Id, BackRoute: 'device' });
    this.router.navigate(['main/device-model-detail']);
  }

  public openHistory(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, Type: this.deviceType, BackRoute: 'device' });
    this.router.navigate(['main/device-history']);
  }

  public openRCDetails(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, Type: this.deviceType, BackRoute: 'device' });
    this.router.navigate(['main/rc-detail']);

  }

  public openWSDetails(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, Type: this.deviceType, BackRoute: 'device' });
    this.router.navigate(['main/ws-detail']);
  }

  public goToTransitDetails(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, Type: this.deviceType, BackRoute: 'device' });
    this.router.navigate(['main/transit-detail']);
  }

  public goToHeatmap(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'device' });
    this.router.navigate(['main/heatmap']);
  }

  public goToParkings(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'device' });
    this.router.navigate(['main/smart-parking-detail']);
  }

  public goToSmartCrosswalk(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'device' });
    this.router.navigate(['main/smart-crosswalk-detail']);
  }

  public goToTrafficIndex(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'device' });
    this.router.navigate(['main/smart-traffic-detail']);
  }

  public goToPassageDetail(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'device' });
    this.router.navigate(['main/passage-detail']);
  }

  public editInfo(): void {
    if (this.editMode === null) {
      this.editMode = 'info';
      if (this.isAdmin) {
        const formValue = this.form.getRawValue();

        for (const key in formValue) {
            if (formValue.hasOwnProperty(key) && key.startsWith("property")) {
                this.form.get(key)?.enable();
            }
        }

        this.form.controls.name.enable();
        this.form.controls.parentSelected.enable();
        this.form.controls.yearCost.enable();
        this.form.controls.monthCost.enable();
      }
      this.dataEditBackup = { ...this.form.value, ...this.locationForm.value };
    }
  }

  public cancelInfoEdit(): void {
    if (this.editMode === 'info') {
      if (this.dataChanged) {
        const cancelDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        cancelDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            let infoData: Record<string, string> = {}, locationData: Record<string, string> = {};
            Object.keys(this.dataEditBackup).forEach((formKey: string) => {
              if (this.form.controls[formKey]) {
                infoData[formKey] = this.dataEditBackup[formKey];
              }
              else if (this.locationForm.controls[formKey]) {
                locationData[formKey] = this.dataEditBackup[formKey];
              }
            });

            this.form.patchValue(this.dataEditBackup);
            this.locationForm.patchValue(this.dataEditBackup);

            this.dataEditBackup = null;
            this.editMode = null;
            this.dataChanged = false;
            this.form.disable();
          }
        });
      }
      else {
        this.dataEditBackup = null;
        this.editMode = null;
        this.form.disable();
      }
    }
  }

  public editLocation(): void {
    if (this.editMode === null && this.form.controls.parentSelected.value === '' && this.isAdmin) {
      this.editMode = 'location';
      this.locationForm.enable();
      this.dataEditBackup = this.locationForm.value;
    }
  }

  public cancelLocationEdit(): void {
    if (this.editMode === 'location') {
      if (this.dataChanged) {
        const cancelDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        cancelDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.locationForm.patchValue(this.dataEditBackup);
            this.dataEditBackup = null;
            this.editMode = null;
            this.dataChanged = false;
            this.locationForm.disable();
          }
        });
      }
      else {
        this.dataEditBackup = null;
        this.editMode = null;
        this.locationForm.disable();
      }
    }
  }

  public updateInfo(): void {
    if (this.editMode === 'info' && this.form.valid) {
      this.update();
    }
    else if (!this.form.valid) {
      this.showFormError = true;
    }
  }

  public updateLocation(): void {
    if (this.editMode === 'location' && this.locationForm.valid) {
      this.update();
    }
    else if (!this.locationForm.valid) {
      this.showLocationFormError = true;
    }
  }

  public updateDeviceMarkerPosition(device: Device): void {
    if (device.Id === this.currentDevice.Id && this.form.controls.parentSelected.value === '') {
      this.locationForm.controls.latitude.setValue(device.Latitude);
      this.locationForm.controls.longitude.setValue(device.Longitude);
    }
  }

  public update(): void {
    if (this.isAdmin || this.isDomainAdmin) {
      const updateDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.form.disable();
          this.locationForm.disable();

          if (this.dataEditBackup.selectedParent !== this.form.controls.parentSelected.value) {
            this.updateParent();
          }

          this.dataEditBackup = null;
          this.editMode = null;
          this.dataChanged = false;

          let selected: string = this.form.controls.parentSelected.value;

          let parent: Device = selected && selected !== undefined && selected !== ''
            ? this.parents.find(x => x.Id === this.currentDevice.ParentId)
            : null;

          this.updateRequest = {
            DeviceId: this.currentDevice.Id,
            ParentId: '',
            Name: '',
            Latitude: '',
            Longitude: '',
            Properties: []
          }

          if (this.form.controls.parentSelected.value != '' && this.form.controls.parentSelected.value != '00000000-0000-0000-0000-000000000000') {
            this.updateRequest.Latitude = parent ? parent.Latitude.toString() : '0';
            this.updateRequest.Longitude = parent ? parent.Longitude.toString() : '0';
          }
          else {
            this.updateRequest.Latitude = this.locationForm.controls.latitude.value.toString();
            this.updateRequest.Longitude = this.locationForm.controls.longitude.value.toString();
          }

          this.updateRequest.Name = this.form.controls.name.value;
          this.updateRequest.ParentId = this.form.controls.parentSelected.value;

          if (this.properties !== undefined || this.properties !== null) {
            this.updateRequest.Properties = [];
            this.properties.forEach(property => {
              if (property.Key === 'Id' && this.isHexadecimal) {
                let value = this.form.controls['property' + property.Key]?.value;
                if (value != undefined || value != null) {
                  this.devicePropertyModel = {
                    DeviceId: this.currentDevice.Id,
                    Property: property.Key.trim(),
                    Value: value === undefined || value === null ? '' : parseInt(value, 16).toString()
                  }
                  this.updateRequest.Properties.push(this.devicePropertyModel);
                }
              } else {
                let value = (<HTMLInputElement>document.getElementById(property.Key))?.value;
                if (value != undefined || value != null) {
                  this.devicePropertyModel = {
                    DeviceId: this.currentDevice.Id,
                    Property: property.Key.trim(),
                    Value: !value ? '' : value
                  }
                  this.updateRequest.Properties.push(this.devicePropertyModel);
                }
              }
            });
          }
          //this.mainService.updateDeviceComand(this.updateRequest);
          this.apiService.updateDevice(this.updateRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.initializeDevice(this.currentDevice.Id);
          })
        }
        this.isHexadecimal = false;
      });
    }
  }

  public delete(): void {
    if (this.currentDevice.Id != '' && this.isAdmin && (this.childs === undefined || this.childs === null)) {
      const deleteDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });
      deleteDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.deleteRequest = {
            DeviceId: this.currentDevice.Id
          }
          this.mainService.setNavigationInfoComand({ Back: true });
          this.mainService.deleteDeviceComand(this.deleteRequest);
        }
      });
    }
  }

  public addChild(): void {
    const addChildDialog = this.dialog.open(AddDeviceDialogComponent, {
      data: {
        latitude: this.currentDevice.Latitude,
        longitude: this.currentDevice.Longitude,
        parents: this.listDevices,
        brands: this.listBrands,
        models: this.listModels,
        parent: this.currentDevice
      },
      maxWidth: '450px'
    });

    addChildDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.addDevice(result).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.initializeDevice(this.currentDevice.Id);
        })
      }
    });
  }

  public openStream(): void {
    if (this.currentDevice) this.openAddStream()
  }

  public openChildStream(child: Device): void {
    if (child && child.Model?.Type?.Name === 'camera') {
      const addDeviceDialogRef = this.dialog.open(StreamingDialogComponent, {
        width: '900px',
        data: {
          device: child
        }
      });

      addDeviceDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    }
  }

  private openAddStream(): void {
    const addDeviceDialogRef = this.dialog.open(StreamingDialogComponent, {
      width: '900px',
      data: {
        device: this.currentDevice
      }
    });

    addDeviceDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
  }

  public changeCosts($event): void {
    const retention = $event.target.value;
    if (retention !== undefined) this._setCosts(retention)
  }

  private _setCosts(retention): void {
    if (retention <= 0) {
      this.form.controls.yearCost.setValue(0);
      this.form.controls.monthCost.setValue(0);
      return;
    }

    if (this.clientSettings !== undefined && this.clientSettings.Settings !== undefined) {
      let key: string;

      if (retention <= 5) key = 'cost_daily_storage_5'
      else if (retention <= 20) key = 'cost_daily_storage_20'
      else key = 'cost_daily_storage_over'

      const weight: number = Number(this.clientSettings.Settings[key]);
      const yearCost: number = (weight + retention / 10) * 365;
      const monthCost: number = yearCost / 12;

      this.form.controls.yearCost.setValue(yearCost.toFixed(2));
      this.form.controls.monthCost.setValue(monthCost.toFixed(2));
    }
  }

  public setHexadecimal(): void {
    let temporaryValue = this.idDecHex.nativeElement.value;
    this.properties.forEach(element => {
      if (element.Key === 'Id') {
        if (!this.isHexadecimal) {
          let number = +temporaryValue;
          this.form.patchValue({ propertyId: number.toString(16) });
          element.Value = number.toString(16);
          this.isHexadecimal = true;
        }
        else {
          let number = parseInt(temporaryValue, 16);
          this.form.patchValue({ ['property' + element.Key]: number.toString()});
          element.Value = number.toString();
          this.isHexadecimal = false;
        }
      }
    });
  }

  sanitize(inputUrl: string) {
    if (inputUrl === '') {
      return;
    }

    let sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(inputUrl);

    return sanitizedUrl;
  }

  loadStreaming() {
    if (navigator.userAgent.indexOf("Win") != -1) {
      const copyLinkDialog = this.dialog.open(StreamingLinkDialogComponent, {
        width: '25%',
        data: {
          link: this.streamLink
        }
      });
      copyLinkDialog.afterClosed();
    }
    else {
      window.location.href = this.streamLink;
    }
  }

  public addAlias(): void {
    const addAliasDialog = this.dialog.open(AddDeviceAliasDialogComponent);

    addAliasDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(alias => {
      if (alias) {
        const addAliasConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addAliasConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            let deviceAlias: DeviceAlias = {
              Device: this.currentDevice.Id,
              Alias: alias
            }
            this.apiService.addDeviceAlias(deviceAlias).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.initializeDevice(this.currentDevice.Id);
            })
          }
        });
      }
    });
  }

  public deleteAlias(alias: DeviceAlias): void {
    if (alias && alias !== undefined) {
      const deleteAliasConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteAliasConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.apiService.deleteDeviceAlias(alias).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.initializeDevice(this.currentDevice.Id);
          })
        }
      });
    }

  }

  public deviceEvents(): void {
    if (this.currentDevice) {
      this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'device' });
      this.router.navigate(['main/device-events']);
    }
  }

  public openPeopleCounterDetails(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'device' });
    this.router.navigate(['main/people-counter']);
  }

  public openTrashBinDetails(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'device' });
    this.router.navigate(['main/trash-bin-detail']);
  }

  public sendCommand(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'device' })
    this.router.navigate(['main/device-command']);
  }

  public commands(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'device' })
    this.router.navigate(['main/device-commands']);
  }

  public notifications(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'device' })
    this.router.navigate(['main/device-notifications']);
  }

  public bacnetMonitor(): void {
    this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'device' })
    this.router.navigate(['main/bacnet-monitor']);
  }

  public executeAction(capabilityId: string): void {
    if (!capabilityId || capabilityId === undefined || capabilityId === '') {
      return;
    }

    let action = this.currentDevice.Model.Type.Capabilities.find(x => x.Id == capabilityId);

    if (!action || action === undefined) {
      return;
    }

    if (action.Action.substr(0, 1) === '{' && this.userRoles.some(x => x.Name === 'Administrators' || x.Name === 'Domain admin')) {
      let deviceGroupCapability: DeviceGroupCapability = SerializationHelper.toInstance(action.Action);

      if (deviceGroupCapability === undefined) {
        return;
      }

      const smartDialog = this.dialog.open(SmartDialogComponent, {
        disableClose: false,
        data: {
          body: deviceGroupCapability.Body,
          parameters: deviceGroupCapability.Parameters,
          action: deviceGroupCapability.Path
        },
        minWidth: 250,
        width: '30%'
      })

      smartDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        if (data) {

          const executeActionConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false
          });

          executeActionConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              if (data.parameters != '') {
                deviceGroupCapability.Parameters = data.parameters;
              }

              if (deviceGroupCapability.Parameters !== '' && deviceGroupCapability.Parameters !== undefined) {
                deviceGroupCapability.Path = deviceGroupCapability.Path + '?' + deviceGroupCapability.Parameters;
              }

              if (data.body !== undefined && data.body !== '') {
                deviceGroupCapability.Body = data.body;
              }

              if (deviceGroupCapability.Body !== '') {
                this.administratorApiService.executePost(deviceGroupCapability.Path, deviceGroupCapability.Body).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                  if (res) {
                    this.mainService.setSuccessMessageComand(res.Message);
                  }
                });
              }
              else {
                this.administratorApiService.executeGet(deviceGroupCapability.Path).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                  if (res) {
                    this.mainService.setSuccessMessageComand(res.Message);
                  }
                });
              }
            }
          });
        }
      });
    }
    else {
      this[action.Action](); // call it
    }

  }

  removeFromGroup(deviceGroup: DeviceGroup) {
    if (deviceGroup) {
      const deleteDeviceFromGroupConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteDeviceFromGroupConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.administratorApiService.deviceGroupRemove(this.currentDevice.Id, deviceGroup.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this.initializeDevice(this.currentDevice.Id);
            }
          });
        }
      });
    }
  }

  removeFromParent(device: Device) {
    if (device) {
      const deleteDeviceFromGroupConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteDeviceFromGroupConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.administratorApiService.deviceParentRemove(device.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            if (x) {
              this.initializeDevice(this.currentDevice.Id);
            }
          });
        }
      });
    }
  }

  addDeviceToGroup(): void {
    const addDeviceGroupDialogRef = this.dialog.open(DeviceGroupSetDialogComponent, {
      data: {
        groups: this.deviceGroups,
        devices: [this.currentDevice],
        fromDevice: true
      },
      width: '50%',
      maxWidth: 500
    });

    addDeviceGroupDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const addSettingConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addSettingConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.deviceGroupSet(data.deviceId, data.groupId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this.initializeDevice(this.currentDevice.Id);
              }
            });
          }
        });
      }
    });
  }

  changeDomain(): void {
    if (this.isAdmin) {
      this.apiService.getDomainsAsAdmin().pipe(takeUntil(this.ngUnsubscribe)).subscribe(domains => {
        const chooseDomainDialogRef = this.dialog.open(SetDomainDialogComponent, {
          data: { domains: domains }
        });
        chooseDomainDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: Domain) => {
          if (result) {
            this.apiService.deviceChangeDomain(this.currentDevice.Id, result.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
              this.mainService.setNavigationInfoComand();
              this.mainService.updateAllDataAndNavigateComand('dashboard');
            });
          }
        });
      });
    }
  }

  public addEvent(): void {
    if (this.isAdmin) {
      const addEventDialog = this.dialog.open(ManualEventDialogComponent, {
        disableClose: false,
        data:{
          deviceId: this.currentDevice.Id
        }
      });

      addEventDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(deviceEvent => {
        if (deviceEvent) {
          const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false
          })

          confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if(result){
              this.administratorApiService.deviceEventAdd(deviceEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                this.initializeDevice(this.currentDevice.Id);
              });
            }
          });
        }
      });
    }

  }

  copyToClipboard() {
    this.clipboard.copy(this.form.controls.id.value);
    this.translate.get('GENERAL.COPIED_TO_CLIPBOARD').pipe(takeUntil(this.ngUnsubscribe)).subscribe(translated => {
      if (translated) {
        this.mainService.setSuccessMessageComand(translated);
      }
    });
  }

  public applyFilter(event: KeyboardEvent, data: string): void {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    if (data === 'aliasesData') {
      this.filteredAliasesData = this.aliasesDataSource?.data.filter((device: DeviceAlias) =>
        [device.Alias.toLowerCase()]
        .some((field: string) => field.includes(filterValue)
      ));
    } else if (data === 'childsData') {
      this.filteredChildsData = this.childsDataSource?.data.filter((device: Device) =>
        [device.Name.toLowerCase()]
        .some((field: string) => field.includes(filterValue)
      ));
    } else if (data === 'groupsData') {
      this.filteredGroupsData = this.groupsDataSource?.data.filter((group: DeviceGroup) =>
        [group.Name.toLowerCase()]
        .some((field: string) => field.includes(filterValue)
      ));
    }
  }

  public applyFilterString(filter: string, data: string): void {
    const filterValue = filter.trim().toLowerCase();

    if (data === 'aliasesData') {
      this.filteredAliasesData = this.aliasesDataSource?.data.filter((device: DeviceAlias) =>
        [device.Alias.toLowerCase()]
        .some((field: string) => field.includes(filterValue)
      ));
    } else if (data === 'childsData') {
      this.filteredChildsData = this.childsDataSource?.data.filter((device: Device) =>
        [device.Name.toLowerCase()]
        .some((field: string) => field.includes(filterValue)
      ));
    } else if (data === 'groupsData') {
      this.filteredGroupsData = this.groupsDataSource?.data.filter((group: DeviceGroup) =>
        [group.Name.toLowerCase()]
        .some((field: string) => field.includes(filterValue)
      ));
    }
  }

  public paginatorOnPageChange(number: number, data: string) {
    if (data === 'aliasesData') {
      this.aliasesConfig.currentPage = number;
    } else if (data === 'childsData') {
      this.childsConfig.currentPage = number;
    } else if (data === 'groupsData') {
      this.groupsConfig.currentPage = number;
    }
  }

  public paginatorGetMaxPage(data: string): number {
    let maxPage: number;

    if (data === 'aliasesData') {
      maxPage = this.aliasesDataSource?.data.length / this.aliasesConfig.itemsPerPage;
    } else if (data === 'childsData') {
      maxPage = this.childsDataSource?.data.length / this.childsConfig.itemsPerPage;
    } else if (data === 'groupsData') {
      maxPage = this.groupsDataSource?.data.length / this.groupsConfig.itemsPerPage;
    }

    maxPage = Math.ceil(maxPage);
    return maxPage
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
