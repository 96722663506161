<div class="d-flex justify-content-start flex-column profileContainer">

    <i class="urbanicon-back" (click)="closeThisRightbar()"></i>

    <div class="detailBox d-flex justify-content-between align-items-baseline flex-wrap">
        <h3 class="title3">{{ 'GENERAL.USER' | translate }}</h3>

        <button *ngIf="isAdmin || isDomainAdmin" type="button" mat-button class="btn outlineActionButton" (click)="goToUserDetail()">Dettagli amministrativi</button>
    </div>

    <div class="propic position-relative">
        <ngx-dropzone id="ngx1" (change)="profileImageUpload($event)" class="imageDropzone"
            accept=".png, .jpg, .jpeg, .gif">
            <img *ngIf="profileImage" src="{{ profileImage }}" alt="Profile Image">
            <div class="noPropic" *ngIf="!profileImage">
                <i class="urbanicon-profile-outline"></i>
            </div>
        </ngx-dropzone>
        <div class="propicActions">
            <button type="button" mat-icon-button [matMenuTriggerFor]="propicActions" tabindex="20" [disableRipple]="true">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
        <mat-menu #propicActions="matMenu">
            <input style="display: none;" type="file" (change)="profileImageUpload($event)" accept=".png, .jpg, .jpeg, .gif" #imageInput id="imageInput">
            <button mat-menu-item (click)="imageInput.click()">
                <span class="linkText">{{ 'GENERAL.EDIT' | translate }}</span>
            </button>

            <button *ngIf="profileImage" mat-menu-item (click)="removePropic()">
                <span class="linkText">{{'GENERAL.DELETE' | translate }}</span>
            </button>
        </mat-menu>
    </div>

    <mat-error *ngIf="imageError" class="mb-3">
        {{ 'ERROR.'+imageError | translate }}
    </mat-error>

    <div class="userRecap">
        <span class="subheadText username">{{ currentUser.Username }}</span>
        <p class="paragraphText- mb-0">{{ currentUser.Firstname }} {{ currentUser.Lastname }}</p>
        <div class="d-flex flex-wrap" *ngIf="userRoles?.length > 0">
            <p class="paragraphboldText pr-1 mb-0">
                {{ userRoles[0].Name }}<span> - {{ myCurrentDomainRef }}</span>
            </p>
        </div>
    </div>

    <span class="subheadText mb-4">{{ 'PROFILE.PERSONAL_INFO' | translate }}</span>

    <div class="d-flex">
        <div class="d-flex flex-column mb-3 col-6 pl-0">
            <mat-label class="paragraphText secondaryTextColor">{{ 'GENERAL.FIRSTNAME' | translate }}:</mat-label>
            <mat-label class="paragraphText">{{ currentUser.Firstname }}</mat-label>
        </div>

        <div class="d-flex flex-column mb-3 col-6 pl-0">
            <mat-label class="paragraphText secondaryTextColor">{{ 'GENERAL.LASTNAME' | translate }}:</mat-label>
            <mat-label class="paragraphText">{{ currentUser.Lastname }}</mat-label>
        </div>
    </div>

    <div class="d-flex flex-column mb-3">
        <mat-label class="paragraphText secondaryTextColor">{{ 'PROFILE.USERNAME' | translate }}:</mat-label>
        <mat-label class="paragraphText">{{ currentUser.Username }}</mat-label>
    </div>

    <div class="d-flex flex-column">
        <mat-label class="paragraphText secondaryTextColor">{{ 'PROFILE.ROLES' | translate }}:</mat-label>
        <div class="d-flex flex-wrap">
            <div class="roleContainer" *ngFor="let role of userRoles"
                [ngStyle]="{'border-color' : colors[role.Name] ? colors[role.Name] : colors['other'],
                            'color' : colors[role.Name] ? colors[role.Name] : colors['other']}">
                <p class="paragraphText"> {{ role.Name}}</p>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column mb-3">
        <mat-label class="paragraphText secondaryTextColor">{{ 'PROFILE.MAIL' | translate }}:</mat-label>
        <mat-label class="paragraphText">{{ currentUser.Email }}</mat-label>
    </div>

    <div class="mb-4">
        <div class="d-flex flex-column" *ngIf="!phoneModificationActive">
            <mat-label class="paragraphText secondaryTextColor">{{ 'PROFILE.PHONE' | translate }}</mat-label>
            <div class="d-flex justify-content-between align-items-center">
                <mat-label class="paragraphText">{{ this.profilePhoneNumber }}</mat-label>

                <div>
                    <label for="imageInput" class="link-text link"
                        (click)="this.phoneModificationActive = !this.phoneModificationActive">{{ 'GENERAL.EDIT' | translate }}</label>
                    <i class="iconInfoButton urbanicon-edit"></i>
                </div>
            </div>
        </div>

        <div *ngIf="phoneModificationActive" class="mb-3">
            <form [formGroup]="phoneForm" class="d-flex flex-column">
                <mat-form-field class="customMatFormField mb-3" appearance="outline">
                    <mat-label>{{ 'PROFILE.PREFIX' | translate }}</mat-label>
                    <mat-select formControlName="prefix" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let prefix of myPrefixesList" [value]="prefix.Code">
                            <span class="flag-icon flag-icon-{{ prefix.Flag }}"></span>
                            {{ prefix.Country }}
                            {{ prefix.Code }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'PROFILE.PHONE' | translate }}</mat-label>
                    <input matInput formControlName="telephone" class="paragraphText" autocomplete="off">
                    <i matSuffix class="urbanicon-phone-outline"></i>
                </mat-form-field>
            </form>
        </div>

        <div *ngIf="phoneModificationActive">
            <mat-button-toggle-group class="searchRangeBtn" hideSingleSelectionIndicator="true">
                <mat-button-toggle (click)="cancelPhoneEdit()">{{'GENERAL.CANCEL' | translate }}</mat-button-toggle>
                <mat-button-toggle (click)="updatePhoneNumber()" [disabled]="!phoneForm.valid || !dataChanged">{{ 'PROFILE.SAVE_NUMBER' | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <button type="button" mat-button class="btn outlineActionButton mb-3" (click)="openChangePasswordDialog()">{{ 'PROFILE.CHANGE_PASSWORD' | translate }}</button>

    <button type="button" mat-button class="btn outlineWarnButton mt-auto" (click)="logoutHandler()">{{ 'PROFILE.LOGOUT' | translate }}</button>

    <div class="py-1"></div>
</div>
