import { EntityField } from './../../../../shared/models/content-management/entityField';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UserRoles} from "../../../../shared/models/userRoles";
import {ContentManagementService} from "../../../../shared/services/content-management.service";
import {Router} from "@angular/router";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {first, takeUntil} from "rxjs/operators";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {EntityResponse} from "../../../../shared/models/content-management/entityResponse";
import {EntityRequest} from "../../../../shared/models/content-management/entityRequest";

@Component({
  selector: 'urban-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss']
})
export class EntityComponent implements OnInit, OnDestroy {

  private entityRequest: EntityRequest;
  public entityResponse: EntityResponse;
  public entityModel: EntityField[];
  public fieldTypes: string[] = ['string', 'string[]', 'number', 'number[]', 'image', 'boolean', 'object', 'object[]', 'schemaMatrix'];

  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required]
  });

  public error: string | null;
  public userRoles: UserRoles['Roles'];
  private entityId: number;
  public isAdd: boolean;

  constructor(
    private apiService: ContentManagementService,
    private router: Router,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
    private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
        if (navInfo?.Id) {
          this.entityId = +navInfo.Id;
        }

        this.isAdd = this.entityId === 0;

        if (!this.isAdd) {
          this._initialize();
        }
      });
    });

    this.passDataService.currentDarkModeStatus$
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(res => {
            this.isDarkActive = res === true;
          });
  }

  private _initialize(): void {
    this.apiService.getEntity(this.entityId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(entity => {
      if (entity) {
        this.entityResponse = entity;

        this.form.setValue({
          name: this.entityResponse.Name
        });

        try {
          this.entityModel = JSON.parse(this.entityResponse.Content) as EntityField[];
        }
        catch {
          //error
        }
      } else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }
    });

    this.apiService.listEntity().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: EntityResponse[]) => {
      if (res && res.length > 0) {
        res.forEach(entity => {
          this.fieldTypes.push(entity.Name, entity.Name + '[]');
        });
      }
    });
  }

  public update(): void {
    if (this.form.valid && this.entityModel) {

      const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.entityRequest = {
            Id: this.entityResponse?.Id,
            Name: this.form.controls.name.value,
            Content: JSON.stringify(this.entityModel)
          };

          this.apiService.updateEntity(this.entityRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this._initialize();
            }
          });
        }
      });
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  public add(): void {
    if (this.form.valid && this.entityModel) {

      const addConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      addConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.entityRequest = {
            Name: this.form.controls.name.value,
            Content: JSON.stringify(this.entityModel)
          };

          this.apiService.addEntity(this.entityRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this.mainService.setNavigationInfoComand();
              this.router.navigate(['main/content-management/entities']);
            }
          });
        }
      });
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  public addChildField(field: EntityField): void {
    let newChild: EntityField = {
      name: 'field'
    };
    if (!field.children) {
      field.children = [];
    }
    newChild.name += field.children.length.toString();
    field.children.push(newChild);
  }

  public addFirstChildField(): void {
    let newChild: EntityField = {
      name: ''
    };
    if (!this.entityModel) {
      this.entityModel = [];
    }
    this.entityModel.push(newChild);
  }

  public removeChildField(field: EntityField, index: number): void {
    if (field) {

      if (field.children[index].name !== '') {
        this.requestConfirmation(() => this.completeChildRemotion(field, index));
      }
      else {
        this.completeChildRemotion(field, index);
      }
    }
    else {
      if (this.entityModel[index].name !== '') {
        this.requestConfirmation(() => this.completeFirstChildRemotion(index));
      }
      else {
        this.completeFirstChildRemotion(index);
      }
    }
  }

  private requestConfirmation(callback: () => void): void {
    const updateUserConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    updateUserConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        callback();
      }
    });
  }

  private completeChildRemotion(field: EntityField, index: number): void {
    field.children.splice(index, 1);
    if (field.children.length === 0) {
      delete(field.children);
    }
  }

  private completeFirstChildRemotion(index: number): void {
    this.entityModel.splice(index, 1);
  }

  public trackByFn(i: number) {
    return i;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
