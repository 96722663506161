import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UserRoles} from "../../../../shared/models/userRoles";
import {Router} from "@angular/router";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {first, takeUntil} from "rxjs/operators";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {BroadcastClientRequest} from "../../../../shared/models/content-management/broadcastClientRequest";
import {ContentManagementService} from "../../../../shared/services/content-management.service";
import {BroadcastClientResponse} from "../../../../shared/models/content-management/broadcastClientResponse";
import {
  BroadcastClientPropertyRequest
} from "../../../../shared/models/content-management/broadcastClientPropertyRequest";
import {PropertyDialogComponent} from "../../../../shared/dialogs/property-dialog/property-dialog.component";
import {BroadcastClientType} from "../../../../shared/models/content-management/broadcastClientType";
import {PaginationInstance} from "ngx-pagination";
import { BroadcastClientPropagateRequest } from 'src/app/shared/models/content-management/broadcastClientPropagateRequest';

@Component({
  selector: 'urban-broadcast-client',
  templateUrl: './broadcast-client.component.html',
  styleUrls: ['./broadcast-client.component.scss']
})
export class BroadcastClientComponent implements OnInit, OnDestroy {

  public properties: BroadcastClientPropertyRequest[] = [];
  public filteredData: BroadcastClientPropertyRequest[] = [];
  public displayedColumns = ['Key', 'Value', 'Detail'];
  private broadcastClientRequest: BroadcastClientRequest;
  private broadcastClientPropagateRequest: BroadcastClientPropagateRequest;
  public broadcastClient: BroadcastClientResponse;
  public types: BroadcastClientType[] = [];

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required],
    description: [{value: '', disabled: false}, Validators.required],
    type: [{value: 0, disabled: false}, Validators.required]
  });

  public error: string | null;
  public userRoles: UserRoles['Roles'];
  private broadcastClientId: string;
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];

  constructor(
    private apiService: ContentManagementService,
    private router: Router,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
    private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
        if (navInfo?.Id) {
          this.broadcastClientId = navInfo.Id;
        }

        if (!this.broadcastClientId) {
          this.mainService.setNavigationInfoComand();
          this.router.navigate(['main/dashboard']);
        }

        this._initialize();
      });
    });
  }

  private _initialize(): void {
    this.apiService.getBroadcastClient(this.broadcastClientId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(broadcastClient => {
      if (broadcastClient) {
        this.broadcastClient = broadcastClient;

        if (this.broadcastClient) {
          this.properties = [];
          this.form.controls.name.setValue(this.broadcastClient.Name);
          this.form.controls.description.setValue(this.broadcastClient.Description);
          this.form.controls.type.setValue(this.broadcastClient.BroadcastClientType.Id);

          for (const key of Object.keys(this.broadcastClient.Properties)) {
            this.properties.push({
              broadcastClient: this.broadcastClientId,
              key: key,
              value: this.broadcastClient.Properties[key]
            });
          }

          this.filteredData = this.properties;
        } else {
          this.mainService.setNavigationInfoComand();
          this.router.navigate(['main/dashboard']);
        }
      } else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }
    });

    this.apiService.listBroadcastClientTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(broadcastClientTypes => {
      if (broadcastClientTypes) {
        this.types = broadcastClientTypes;
      }
    });
  }

  public update(): void {
    if (this.form.valid) {

      const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {

          this.broadcastClientRequest = {
            id: this.broadcastClientId,
            broadcastClientType: this.form.controls.type.value,
            name: this.form.controls.name.value,
            description: this.form.controls.description.value
          };

          this.apiService.updateBroadcastClient(this.broadcastClientRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this._initialize();
            }
          });
        }
      });
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  public propagate(): void {
    if (this.form.valid) {

      const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {

          this.broadcastClientPropagateRequest = {
            broadcastClientId: this.broadcastClientId
          };

          this.apiService.propagateBroadcastClient(this.broadcastClientPropagateRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this._initialize();
            }
          });
        }
      });
    } else {
      this.error = "ERROR_EMPTY";
    }
  }


  public openAddProperty(): void {
    const addPropertyDialog = this.dialog.open(PropertyDialogComponent, {
      disableClose: false
    });

    addPropertyDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newKeyValue => {
      if (newKeyValue) {
        const addPropertyConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });
        addPropertyConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.propertySetBroadcastClient({
              broadcastClient: this.broadcastClientId,
              key: newKeyValue.key,
              value: newKeyValue.value
            }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public openDetailProperty(property: BroadcastClientPropertyRequest): void {
    if (!property) {
      return;
    }

    const detailPropertyDialog = this.dialog.open(PropertyDialogComponent, {
      data: {key: property.key, value: property.value},
      width: '40%',
      disableClose: false
    });

    detailPropertyDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedKeyValue => {
      if (updatedKeyValue) {
        const updatePropertyConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });
        updatePropertyConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.propertySetBroadcastClient({
              broadcastClient: this.broadcastClientId,
              key: updatedKeyValue.key,
              value: updatedKeyValue.value
            }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public deleteProperty(property: BroadcastClientPropertyRequest): void {
    if (!property) {
      return;
    }

    const deletePropertyConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    deletePropertyConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.propertySetBroadcastClient({
          broadcastClient: this.broadcastClientId,
          key: property.key
        }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            this._initialize();
          }
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.properties?.filter((property: BroadcastClientPropertyRequest) =>
      [
        property.key?.toLowerCase(), 
        property.value?.toLowerCase()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
