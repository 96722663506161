<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'CONTENT_MANAGEMENT.CONTENT' | translate }} : {{contentResponse?.Name}}
        </span>

        <div>
            <button mat-button class="btn actionButton" (click)="openAddProperty()">
                {{ 'GENERAL.ADD_PROPERTY' | translate }}
            </button>
        </div>
    </div>

    <form [formGroup]="form">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.DURATION' | translate }}</mat-label>
            <input type="number" min="0" step="0.1" matInput formControlName="duration" autocomplete="off" required>
        </mat-form-field>

        <div class="d-flex">
            <mat-form-field appearance="outline" class="customMatFormField mb-3"
                [matTooltip]="entities?.length > 0 ? '' : ('CONTENT.NO_ENTITIES' | translate)">
                <mat-label>{{ 'CONTENT.ENTITY_TAG' | translate }}</mat-label>
                <mat-select formControlName="tags" hideSingleSelectionIndicator="true">
                    <mat-option *ngFor="let entity of entities" value={{entity.Name}}>
                        {{entity.Name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button *ngIf="isAdmin && !(entities?.length > 0)" mat-button class="btn actionButton ml-2 mb-3" (click)="goToEntities()">
                {{ 'CONTENT.ADD_NEW_ENTITIES' | translate }}
            </button>
        </div>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'CONTENT_MANAGEMENT.BROADCAST_CLIENT' | translate }}</mat-label>
            <mat-select formControlName="broadcastClient" hideSingleSelectionIndicator="true">
                <mat-option *ngFor="let client of clients" value={{client.Id}}>
                    {{client.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'RESOURCE.IS_PUBLIC' | translate }}</mat-label>
            <mat-select formControlName="isPublic" hideSingleSelectionIndicator="true">
                <mat-option value="false">{{ 'GENERAL.NO' | translate }}</mat-option>
                <mat-option value="true">{{ 'GENERAL.YES' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>

        <div class="d-flex">
            <button mat-button class="btn actionButton mb-3" type="button" id="updateButton"
                (click)="update()">
                {{ 'GENERAL.UPDATE' | translate }}
            </button>
            <input style="display: none;" type="file" (change)="contentUpload($event)"
                accept="{{this.acceptedFileExtensions}}" #contentInput>
            <button mat-button class="btn actionButton ml-2 mb-3"
                (click)="contentInput.click()">
                {{ 'CONTENT_MANAGEMENT.ADD_FILE' | translate }}
            </button>
            <div class="d-inline-flex ml-2 mb-3" [matTooltip]="dataToCompile === undefined ? ('CONTENT_MANAGEMENT.NO_ENTITY_ERROR' | translate) : ''">
                <button mat-button class="btn actionButton"
                    (click)="openCompilationDialog()" [disabled]="dataToCompile === undefined || form.controls.tags.value === ''">
                    {{ 'CONTENT_MANAGEMENT.COMPILE_DATA' | translate }}
                </button>
            </div>
        </div>

        <mat-label *ngIf="fileName">
            {{ fileName }}
        </mat-label>
    </form>

    <button *ngIf="fileNameDownload" title="{{ 'GENERAL.DOWNLOAD' | translate }}" mat-button type="submit"
            class="btn outlineActionButton" (click)="download()">
        <i class="urbanicon-download-outline"></i>
        {{ fileNameDownload }}
    </button>

    <div class="customTable mt-5">
        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <span class="title3">{{ 'GENERAL.PROPERTIES' | translate }}</span>

            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                       autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear"
                        (click)="applyFilterString(''); actualFilter = ''"
                        matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100 user-select-none noTableHover" mat-table [dataSource]="filteredData | paginate: config">

                <ng-container matColumnDef="Key">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.KEY' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.key}} </td>
                </ng-container>

                <ng-container matColumnDef="Value">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.VALUE' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.value.length > 20 ? element.value.slice(0, 20) +
                        "..." : element.value }} </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i class="iconInfoButton urbanicon-info-outline"
                               matTooltip="{{'GENERAL.DETAIL' | translate }}"
                               (click)="openDetailProperty(element)">
                            </i>
                            <i class="iconDeleteButton urbanicon-delete" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                               (click)="deleteProperty(element)">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius paginatorControls d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                                     [maxSize]="7"
                                     [directionLinks]="true"
                                     [previousLabel]="''"
                                     [nextLabel]="''"
                                     [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                                     [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                                     [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                                     (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase() : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
