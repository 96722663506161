import { Router } from '@angular/router';
import { MainSubscriptionsService } from './../../../shared/services/main-subscriptions/main-subscriptions.service';
import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { ApiService } from '../../../shared/services/api.service';
import { Domain } from '../../../shared/models/domain';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AccessCountResponse, AccessListResponse } from '../../../shared/models/accessListElements';
import { SearchDates } from 'src/app/shared/models/searchDates';
import introJs from 'intro.js';
import { ChartDataList } from 'src/app/shared/models/ChartDataList';

@Component({
  selector: 'urban-platform-statistics',
  templateUrl: './platform-statistics.component.html',
  styleUrls: ['./platform-statistics.component.scss']
})
export class PlatformStatisticsComponent implements OnInit, OnDestroy {

  private introJS = introJs();
  public isDarkActive: boolean;
  public myAvailableDomains: Domain[] = [];
  public myCurrentDomain: Domain = null;
  public isOnlyDomainAdmin: boolean = false;
  public myAccessList: AccessListResponse;
  public accesses: ChartDataList;
  public isChartDataReady: boolean = false;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public loadingData: boolean;
  public selectedDomainName: string;
  private subscription: Subject<void> = new Subject<void>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiSync: ApiSynchronizerService,
    private apiService: ApiService,
    private mainService: MainSubscriptionsService,
    private passDataService: PassDataService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(roles => {
      roles.some(role => role.Name == 'Administrators') ? this.isOnlyDomainAdmin = false : this.isOnlyDomainAdmin = true;

      this.passDataService.availableDomains$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.myAvailableDomains = res;
      });

      this.passDataService.currentDomain$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.myCurrentDomain = res;
        this.selectedDomainName = res.Name;
      });

      this.isOnlyDomainAdmin ? this.callApiAsDomainAdmin() : this.callApiAsGeneralAdmin();
    });
  }

  private callApiAsGeneralAdmin(selectedDates?: SearchDates): void {
    this.apiInitializationAndThenChartReady(selectedDates);

    this.apiService.getAccessesListAdmin({
      domain: this.selectedDomainName,
      start: selectedDates?.startDate ? selectedDates.startDate : 0,
      end: selectedDates?.endDate ? selectedDates.endDate : 0
    }).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      if (res && res.Accesses) {
        this.myAccessList = res;
        this.apiSync.loadedFeature();
      }
      else {
        this.myAccessList = { Accesses: null };
        this.apiSync.failedFeature();
      }
    });

    this.apiService.getAccessesCountAdmin({
      domain: this.selectedDomainName,
      start: selectedDates?.startDate ? selectedDates.startDate : 0,
      end: selectedDates?.endDate ? selectedDates.endDate : 0
    }).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      if (res && res.Accesses) {
        this.accesses = this.mapToAccesses(res)
        this.apiSync.loadedFeature();
      }
      else {
        this.accesses = null;
        this.apiSync.failedFeature();
      }
    });
  }

  private callApiAsDomainAdmin(selectedDates?: SearchDates): void {
    this.apiInitializationAndThenChartReady(selectedDates);

    this.apiService.getAccessesListDomainAdmin({
      start: selectedDates?.startDate ? selectedDates.startDate : 0,
      end: selectedDates?.endDate ? selectedDates.endDate : 0
    }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res && res.Accesses) {
        this.myAccessList = res;
        this.accesses = this.mapToAccesses(res)
        this.apiSync.loadedFeature();
      }
      else {
        this.myAccessList = { Accesses: null };
        this.apiSync.failedFeature();
      }
    });

    this.apiService.getAccessesCountDomainAdmin({
      start: selectedDates?.startDate ? selectedDates.startDate : 0,
      end: selectedDates?.endDate ? selectedDates.endDate : 0
    }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res && res.Accesses) {
        this.apiSync.loadedFeature();
      }
      else {
        this.accesses = null;
        this.apiSync.failedFeature();
      }
    });
  }

  private apiInitializationAndThenChartReady(selectedDates?: SearchDates): void {
    this.apiSync.initialize();
    this.apiSync.addFeatures(2);
    this.apiSync.waitFeaturesAndThen(() => {
      if (selectedDates !== null && selectedDates !== undefined) {
        this.last24hSearch = selectedDates.last24hSearch;
      }

      this.loadingData = false;
      this.isChartDataReady = true;
    });
  }

  private mapToAccesses(data: AccessCountResponse): ChartDataList {
    const mappedData: ChartDataList = { };

    Object.entries(data.Accesses).forEach(([date, value]) => {
      const [year, month, day] = date.split('-');
      const italianDate = `${day ?? ''}-${month ?? ''}-${year ?? ''}`;
      mappedData[italianDate] = +value;
    });

    return mappedData;
  }

  public newSearch(selectedDates: SearchDates) {
    if (this.isOnlyDomainAdmin) {
      this.loadingData = true;
      this.callApiAsDomainAdmin(selectedDates);
      return;
    }

    this.loadingData = true;
    this.callApiAsGeneralAdmin(selectedDates);
  }

  public startIntro(): void {
    this.translate.get([
      'INTRO.STATISTICS_WELCOME',
      'INTRO.STATISTICS_DOMAIN',
      'INTRO.SEARCH'
    ])
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(intros => {
      this.introJS
      .setOptions({
        steps: [
          {
            title: 'Welcome',
            intro: intros['INTRO.STATISTICS_WELCOME']
          },
          {
            title: 'Domain',
            element: '#intro-statistics-domain',
            intro: intros['INTRO.STATISTICS_DOMAIN'],
            position: 'right',
            scrollTo: "tooltip"
          },
          {
            title: 'Search bar',
            element: '#intro-statistics-search',
            intro: intros['INTRO.SEARCH'],
            position: 'right'
          }
        ],
        showProgress: true
      })
      .start();
    });
  }

  public objectKeysNumber(object: any): number {
    if (object) {
      return Object.keys(object).length;
    }

    return -1;
  }

  public goToLogsStatistics(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'platform-statistics' });
    this.router.navigate(['main/logs-statistics']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
