import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DeviceModelDialogComponent } from 'src/app/shared/dialogs/device-model-dialog/device-model-dialog.component';
import { DeviceBrand } from 'src/app/shared/models/deviceBrand';
import { DeviceModel } from 'src/app/shared/models/deviceModel';
import { DeviceType } from 'src/app/shared/models/deviceType';
import { AdministratorApiService } from 'src/app/shared/services/administrator-api.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-device-brand-detail',
  templateUrl: './device-brand-detail.component.html',
  styleUrls: ['./device-brand-detail.component.scss']
})
export class DeviceBrandDetailComponent implements OnInit, OnDestroy {

  public modelColumns = ['Icon', 'Name', 'Detail'];
  public deviceBrand: DeviceBrand;
  public deviceModels: DeviceModel[] = [];
  public error: string | null;
  private deviceTypes: DeviceType[];
  public filteredData: DeviceModel[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required]
  });

  constructor(
    private router: Router,
    private administratorApiService: AdministratorApiService,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
  ) { }

  ngOnInit(): void {
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (!navInfo?.Id) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }

      this.loadData(navInfo.Id)
    });
  }

  private loadData(deviceBrandId: string): void {
    this.administratorApiService.deviceBrandGet(deviceBrandId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result.DeviceBrand) {
        this.deviceBrand = result.DeviceBrand;
        this.deviceModels = result.DeviceModels;
        this.filteredData = this.deviceModels;
        this.form.controls.name.setValue(this.deviceBrand.Name);

        this.apiService.getDeviceType().pipe(takeUntil(this.ngUnsubscribe)).subscribe(type => {
          if (type) {
            this.deviceTypes = type;
          }
        });
      }
      else {
        this.setErrorAndGoToMain();
        return;
      }
    });
  }

  public update(): void {
    if (this.form.valid) {

      const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
        if (confirmed) {
          this.deviceBrand.Name = this.form.controls.name.value;

          this.administratorApiService.updateDeviceBrand(this.deviceBrand).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.loadData(this.deviceBrand.Id);
          })
        }
      });
    }
    else {
      this.error = "ERROR_EMPTY";
    }
  }

  public modelDetail(model: DeviceModel): void {
    if (!model || model === undefined) {
      return;
    }

    this.mainService.setNavigationInfoComand({ Id: model.Id, BackRoute: 'device-brand-detail' });
    this.router.navigate(['main/device-model-detail']);
  }

  public addModel(): void {
    const addModelDialogRef = this.dialog.open(DeviceModelDialogComponent, {
      data: { types: this.deviceTypes, brand: this.deviceBrand }
    });

    addModelDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newModel => {
      if (newModel) {
        const addModelConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addModelConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.administratorApiService.addDeviceModel(newModel).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadData(this.deviceBrand.Id);
            });
          }
        });
      }
    });
  }

  public modelDelete(model: DeviceModel): void {
    if (!model || model === undefined) {
      return;
    }

    const deleteModelConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteModelConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.administratorApiService.deleteDeviceModel(model.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.apiService.getDevicesBrands().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.loadData(this.deviceBrand.Id);
          });
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.deviceModels?.filter((model: DeviceModel) =>
      [model.Name?.toLowerCase()]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public openDeviceModelList(model: DeviceModel): void {
    if (!model || model === undefined) {
      return;
    }

    this.mainService.setNavigationInfoComand({ Id: model.Id, Type: this.deviceBrand.Id, BackRoute: 'device-brand-detail' });
    this.router.navigate(['main/device-model-list']);
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
