import {Component, OnDestroy, OnInit} from '@angular/core';
import {BroadcastClientResponse} from "../../../../shared/models/content-management/broadcastClientResponse";
import {Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UserRoles} from "../../../../shared/models/userRoles";
import {PaginationInstance} from "ngx-pagination";
import {ContentManagementService} from "../../../../shared/services/content-management.service";
import {Router} from "@angular/router";
import {PassDataService} from "../../../../shared/services/pass-data/pass-data.service";
import {MatDialog} from "@angular/material/dialog";
import {MainSubscriptionsService} from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import {first, takeUntil} from "rxjs/operators";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {PropertyDialogComponent} from "../../../../shared/dialogs/property-dialog/property-dialog.component";
import {ContentPropertyRequest} from "../../../../shared/models/content-management/contentPropertyRequest";
import {ContentResponse} from "../../../../shared/models/content-management/contentResponse";
import {ContentRequest, FileContentRequest} from "../../../../shared/models/content-management/contentRequest";
import {Configuration} from "../../../../shared/models/configuration";
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { EntityResponse } from 'src/app/shared/models/content-management/entityResponse';
import { EntityField } from 'src/app/shared/models/content-management/entityField';
import { TranslateService } from '@ngx-translate/core';
import { ContentDataDialogComponent } from 'src/app/shared/dialogs/content-data-dialog/content-data-dialog.component';

@Component({
  selector: 'urban-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnDestroy {
  public isAdmin: boolean = false;
  private contentId: string;
  private contentRequest: ContentRequest;
  public contentResponse: ContentResponse;
  public entities: EntityResponse[];
  public dataToCompile: any;
  public clients: BroadcastClientResponse[] = [];
  public appConfig: Configuration;
  public displayedColumns = ['Key', 'Value', 'Detail'];
  public properties: ContentPropertyRequest[] = [];
  public filteredData: ContentPropertyRequest[] = [];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{value: '', disabled: false}, Validators.required],
    duration: [{value: 0, disabled: false}, Validators.required],
    tags: [{value: '', disabled: true}],
    broadcastClient: [{value: '', disabled: false}, Validators.required],
    isPublic: [{value: "", disabled: false}, Validators.required]
  });

  private file: File;
  public fileName: string | null;
  private fileType: string | null;
  private fileExtension: string | null;
  public acceptedFileExtensions: string;
  public fileNameDownload: string | null;
  public fileError: string;
  private base64Content: string | null;
  public error: string | null;

  constructor(
    private apiService: ContentManagementService,
    private apiSync: ApiSynchronizerService,
    private translate: TranslateService,
    private router: Router,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
    private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isAdmin = res.some(x => x.Name === 'Administrators');
    });

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        this.contentId = navInfo.Id;
      }

      if (!this.contentId) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this._initialize();

      this.passDataService.appConfiguration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(config => {
        this.appConfig = config;
        this.acceptedFileExtensions = this.appConfig.Settings.ContentManagementAllowedExtensions.toString();
      })
    });
  }

  private _initialize(): void {
    let syncContext: number = this.apiSync.initialize();
    let contentApi: number, entitiesApi: number, contentDataApi: number;
    this.apiSync.addFeatures(3);

    this.apiSync.waitFeaturesAndThen((checkvalues: boolean[]) => {
      if (!checkvalues[contentApi]) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }

      if (checkvalues[entitiesApi]) {
        let contentEntity: EntityResponse = this.entities.find(entity => this.contentResponse.Tags.toLowerCase().includes(entity.Name.toLowerCase()));
        if (contentEntity?.Content) {
          try {
            contentModel = JSON.parse(contentEntity.Content) as EntityField[];
            let filteredEntities: EntityResponse[] = this.entities.filter(entity => entity.Name !== contentEntity.Name);
            this.nestInternalEntities(contentModel, filteredEntities);
          }
          catch (e) {
            this.mainService.setCustomErrorComand(e);
            return;
          }
        }
        else {
          return;
        }
        let data: any = {
          contentName: this.contentResponse.Name,
          contentModel
        };
        if (checkvalues[contentDataApi] && contentData) {
          data['contentData'] = contentData;
        }

        this.dataToCompile = data;
      }
    });

    this.apiService.getContent(this.contentId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(content => {
      if (content) {
        this.contentResponse = content;
        contentApi = this.apiSync.loadedFeature(syncContext);

        const linkSplit = this.contentResponse.Link.split('/');
        this.fileNameDownload = linkSplit[linkSplit?.length - 1];
        const fileNameSplit = this.fileNameDownload.split('.');
        this.fileExtension = fileNameSplit[fileNameSplit.length - 1];

        this.properties = [];
        this.form.controls.name.setValue(this.contentResponse.Name);
        this.form.controls.duration.setValue(this.contentResponse.Duration);
        this.form.controls.tags.setValue(this.contentResponse.Tags);
        this.form.controls.broadcastClient.setValue(this.contentResponse.BroadcastClientId);
        this.form.controls.isPublic.setValue(this.contentResponse.IsPublic.toString());

        for (const key of Object.keys(this.contentResponse.Properties)) {
          this.properties.push({
            content: this.contentId,
            key: key,
            value: this.contentResponse.Properties[key]
          });
        }

        this.filteredData = this.properties;
      } else {
        contentApi = this.apiSync.failedFeature(syncContext);
      }
    });

    let contentData: any, contentModel: EntityField[];

    this.apiService.getContentData(this.contentId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        contentData = res;
        contentDataApi = this.apiSync.loadedFeature();
      }
      else {
        contentDataApi = this.apiSync.failedFeature();
      }
    });

    this.apiService.listEntity().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: EntityResponse[]) => {
      if (res) {
        this.entities = res;
        entitiesApi = this.apiSync.loadedFeature();

        if (this.entities.length > 0) {
          this.form.controls.tags.enable();
        }
      }
      else {
        this.translate.get('CONTENT_MANAGEMENT.NO_ENTITY_ERROR').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
          if(res) {
            this.mainService.setCustomErrorComand(res);
          }
          entitiesApi = this.apiSync.failedFeature();
        });
      }
    });

    this.apiService.listBroadcastClient().pipe(takeUntil(this.ngUnsubscribe)).subscribe(broadcastClients => {
      if (broadcastClients) {
        this.clients = broadcastClients;
      }
    });
  }

  public update(): void {
    if (this.form.valid) {

      const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.contentRequest = {
            id: this.contentResponse?.Id,
            broadcastClient: this.form.controls.broadcastClient.value,
            name: this.form.controls.name.value,
            tags: this.form.controls.tags.value,
            duration: +this.form.controls.duration.value,
            isPublic: this.form.controls.isPublic.value === "true"
          };

          if (this.fileType && this.fileName && this.base64Content) {
            this.contentRequest.file = {
              base64Content: this.base64Content,
              mimeType: this.fileType,
              fileName: this.fileName
            }
          }

          this.apiService.updateContent(this.contentRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
            if (result) {
              this._initialize();
            }
          });
        }
      });
    } else {
      this.error = "ERROR_EMPTY";
    }
  }

  public download(): void {
    this.apiService.downloadContent(this.contentId, this.fileExtension).pipe(takeUntil(this.ngUnsubscribe)).subscribe(_ => _);
  }

  public contentUpload(event: any): void {
    if (event.target.files[0].size > +this.appConfig.Settings.ContentManagementMaxFileSize) {
      this.fileError = 'CONTENT_SIZE_MAX';
    } else {
      this.file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (_) => {
        if (_.loaded === _.total) {
          this.base64Content = reader.result.toString().split(',')[1];
          this.fileName = this.file.name;
          this.fileType = this.file.type;
        }
      };
      reader.onerror = function (error) {
        console.log("Error FileReader: ", error);
      };
    }
  }

  public openAddProperty(): void {
    const addPropertyDialog = this.dialog.open(PropertyDialogComponent, {
      disableClose: false
    });

    addPropertyDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newKeyValue => {
      if (newKeyValue) {
        const addPropertyConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });
        addPropertyConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.propertySetContent({
              content: this.contentId,
              key: newKeyValue.key,
              value: newKeyValue.value
            }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        })
      }
    });
  }

  public openDetailProperty(property: ContentPropertyRequest): void {
    if (!property) {
      return;
    }

    const detailPropertyDialog = this.dialog.open(PropertyDialogComponent, {
      data: {key: property.key, value: property.value},
      width: '40%',
      disableClose: false
    });

    detailPropertyDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedKeyValue => {
      if (updatedKeyValue) {
        const updatePropertyConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });
        updatePropertyConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.propertySetContent({
              content: this.contentId,
              key: updatedKeyValue.key,
              value: updatedKeyValue.value
            }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        })
      }
    });
  }

  public deleteProperty(property: ContentPropertyRequest): void {
    if (!property) {
      return;
    }

    const deletePropertyConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    deletePropertyConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.propertySetContent({
          content: this.contentId,
          key: property.key
        }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            this._initialize();
          }
        });
      }
    })
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.properties?.filter((property: ContentPropertyRequest) =>
      [property.key?.toLowerCase(), property.value?.toLowerCase()]
        .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  private nestInternalEntities(entityModel: EntityField[], entities: EntityResponse[]): void {
    entityModel.forEach((entityField: EntityField) => {
      let entityType: string;

      if (entityField.type.includes('[]')) {
        entityType = entityField.type.replace('[]', '');
      }
      else {
        entityType = entityField.type;
      }

      if (['string', 'number'].includes(entityType)) {
        return;
      }

      if (entityType === 'object' && entityField.children?.length > 0) {
        this.nestInternalEntities(entityField.children, entities);
      }
      else {
        let entityFound: EntityResponse = entities.find(entityToCheck => entityToCheck.Name === entityType);

        if (entityFound?.Content) {
          try {
            entityField.children = JSON.parse(entityFound.Content) as EntityField[];
            entityField.type = entityField.type.replace(entityType, 'object');
            this.nestInternalEntities(entityField.children, entities.filter(entity => entity.Name !== entityFound.Name));
          }
          catch (_) {
            return;
          }
        }
      }
    });
  }

  public openCompilationDialog(): void {
    const contentDataDialogRef = this.dialog.open(ContentDataDialogComponent, {
      disableClose: false,
      width: '80%',
      data: this.dataToCompile
    });

    contentDataDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((fileContent: FileContentRequest) => {
      if (fileContent) {
        this.file = undefined;
        this.fileName = fileContent.fileName;
        this.fileType = fileContent.mimeType;
        this.base64Content = fileContent.base64Content;

        this.update();
      }
    });
  }

  public goToEntities(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'content-management/content'});
    this.router.navigate(['main/content-management/entities']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
